import type { StateCreator } from 'zustand'

import type { GuestInfo, UserInfo } from 'lib/auth'

export interface SessionState {
  session: UserInfo | GuestInfo
  v2Token: string
}

export interface SessionAction {
  setSession: (session: SessionState['session']) => void
  setV2Token: (v2Token: string) => void
}

export const createSessionSlice: StateCreator<
  SessionState & SessionAction
> = set => ({
  session: {
    access_token: '',
    user_id: '',
    guest: true,
  },
  v2Token: '',
  setSession: session => set({ session }),
  setV2Token: v2Token => set({ v2Token }),
})
