import { ENV, type Env } from './constants'

// === API ===

const DEVELOP_API = {
  ASGARD: 'https://asgard.paladise.xyz/web/v1',
  USER: 'https://ai-user.paladise.xyz/web/v1',
  MEDIA: 'https://bragi.paladise.xyz/web/v1',
  SESSION: 'https://humanq-analytics.paladise.xyz',
  HERMES: 'https://hermes.paladise.xyz/web/v1',
  HERMES_SRV: 'https://hermes.paladise.xyz/srv/v1',
  CHAT: 'https://ai-bifrost.http.paladise.xyz/web/v1',
} as const

const BETA_API = {
  ASGARD: 'https://asgard.paladise.bet/web/v1',
  USER: 'https://ai-user.paladise.bet/web/v1',
  MEDIA: 'https://bragi.paladise.bet/web/v1',
  SESSION: 'https://humanq-analytics.paladise.bet',
  HERMES: 'https://hermes.paladise.bet/web/v1',
  HERMES_SRV: 'https://hermes.paladise.bet/srv/v1',
  CHAT: 'https://ai-bifrost.http.paladise.bet/web/v1',
} as const

const PROD_API = {
  ASGARD: 'https://asgard.paladise.ai/web/v1',
  USER: 'https://ai-user.paladise.ai/web/v1',
  MEDIA: 'https://bragi.paladise.ai/web/v1',
  SESSION: 'https://humanq-analytics.paladise.ai',
  HERMES: 'https://hermes.paladise.ai/web/v1',
  HERMES_SRV: 'https://hermes.paladise.ai/srv/v1',
  CHAT: 'https://ai-bifrost.http.paladise.ai/web/v1',
} as const

// K8S: micro-app-server
// const K8S_API = {
// } as const

export const apiGenerator = (env: Env) => {
  // if (typeof window === 'undefined' && process.env.NODE_ENV === 'production') {
  //   return K8S_API
  // }

  if (env === 'local' || env === 'develop' || env === 'stage') {
    return DEVELOP_API
  }
  if (env === 'beta') {
    return BETA_API
  }

  if (env === 'master') {
    return PROD_API
  }

  return DEVELOP_API
}

export const API = apiGenerator(ENV.ENV)

// === HOST ===

export const DEVELOP_HOST = {
  MQTT: 'wss://mq.paladise.xyz:443/mqtt',
  NOTIFY: 'wss://websocket.paladise.xyz/ws',
  LIVEKIT: 'wss://humanate-v7fhbov5.livekit.cloud',
  TRACKER: 'wss://humanq-analytics.paladise.xyz/api/tracker/log_event/ws',
}

export const BETA_HOST = {
  MQTT: 'wss://mq.paladise.bet:443/mqtt',
  NOTIFY: 'wss://websocket.paladise.bet/ws',
  LIVEKIT: 'wss://humanate-v7fhbov5.livekit.cloud',
  TRACKER: 'wss://humanq-analytics.paladise.bet/api/tracker/log_event/ws',
}

export const PROD_HOST = {
  MQTT: 'wss://mq.paladise.ai:443/mqtt',
  NOTIFY: 'wss://websocket.paladise.ai/ws',
  LIVEKIT: 'wss://humanate-v7fhbov5.livekit.cloud',
  TRACKER: 'wss://humanq-analytics.paladise.ai/api/tracker/log_event/ws',
}

export const hostGenerator = (env: Env) => {
  if (env === 'local' || env === 'develop' || env === 'stage') {
    return DEVELOP_HOST
  }
  if (env === 'beta') {
    return BETA_HOST
  }

  if (env === 'master') {
    return PROD_HOST
  }

  return DEVELOP_HOST
}

export const HOST = hostGenerator(ENV.ENV)
