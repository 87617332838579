import { sessionTrackerInActive, sessionTrackerResumed } from 'api/tracker'
import { clientFetch } from 'lib/fetch/client'
import { useCallback, useEffect, useRef } from 'react'
import type { SessionTrackerBody } from '@paladise/tracker/types/tracker'

export const useVisibilityTracker = (
  sessionTrackerBody: SessionTrackerBody,
  sessionId: string,
) => {
  const initialTrackerRef = useRef<boolean>(false)

  const handleResumedTracker = useCallback(async () => {
    await sessionTrackerResumed(clientFetch, {
      ...sessionTrackerBody,
      id: sessionId,
    })
  }, [sessionTrackerBody, sessionId])

  const handleInactiveTracker = useCallback(async () => {
    await sessionTrackerInActive(clientFetch, {
      ...sessionTrackerBody,
      id: sessionId,
    })
  }, [sessionTrackerBody, sessionId])

  useEffect(() => {
    const handleVisibilityChange = () => {
      document.visibilityState === 'visible'
        ? handleResumedTracker()
        : handleInactiveTracker()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    if (!initialTrackerRef.current) {
      initialTrackerRef.current = true
      handleResumedTracker()
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleInactiveTracker, handleResumedTracker])
}
