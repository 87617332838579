'use client'

import { usePathname } from 'lib/navigation'

const PrefetchVAD = () => {
  const pathname = usePathname()
  if (pathname !== '/dashboard' && !pathname.includes('/embed')) return null
  return (
    <>
      <link rel="prefetch" href="/vad/ort-wasm-simd.wasm" />
      <link rel="prefetch" href="/vad/ort-wasm.wasm" />
      <link rel="prefetch" href="/vad/silero_vad.onnx" />
      <link rel="prefetch" href="/vad/vad.worklet.bundle.min.js" />
    </>
  )
}

export default PrefetchVAD
