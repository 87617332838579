'use client'
import { useRef } from 'react'

import type { GuestInfo, UserInfo } from 'lib/auth'
import { useBoundStore } from 'store/useBoundStore'

export const SessionInitializer = (props: {
  session: UserInfo | GuestInfo
  v2Token: string
}) => {
  const { session, v2Token } = props
  const setSession = useBoundStore(state => state.setSession)
  const setV2Token = useBoundStore(state => state.setV2Token)
  const initialRef = useRef(false)
  if (!initialRef.current) {
    setSession(session)
    setV2Token(v2Token)
    initialRef.current = true
  }

  return null
}
