import { useCallback } from 'react'
import { useBoundStore } from 'store/useBoundStore'
import {
  generateEventTracker,
  generateViewTracker,
} from '../utils/generateTracker'

export type HandleEventTracker = {
  eventName: string
  parameters?: {
    content_id: string
    content_type: 'avatar'
    avatar_status: 'default' | 'subscribed' | 'expired'
    engagement_limit: string
    engagement_count: string
  }
}

export function useSendTracker() {
  const sendTrackerMessage = useBoundStore().sendMessage

  const handleScreenTracker = useCallback(
    ({ screenName }: { screenName: string }) => {
      const message = generateViewTracker({
        parameters: { screen_name: screenName, screen_class: screenName },
      })
      sendTrackerMessage(message)
    },
    [sendTrackerMessage],
  )

  const handleEventTracker = useCallback(
    ({ eventName, parameters }: HandleEventTracker) => {
      const message = generateEventTracker({ eventName, parameters })
      sendTrackerMessage(message)
    },
    [sendTrackerMessage],
  )

  return { handleScreenTracker, handleEventTracker }
}
