'use client'

import React from 'react'
import { browserName, browserVersion } from 'react-device-detect'
import { cn } from '@paladise/ui/lib/cn'

const Debugger = ({
  apiEnv,
  commitHash,
}: {
  apiEnv: string
  commitHash?: string
}) => {
  const [show, setShow] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const browser = React.useSyncExternalStore(
    () => () => {},
    () => `${browserName} ${browserVersion}`,
    () => '',
  )

  React.useEffect(() => {
    let isLongPress = false
    let timer: NodeJS.Timeout
    let el = ref.current!

    const handlePointerDown = (e: MouseEvent) => {
      isLongPress = false
      timer = setTimeout(() => {
        isLongPress = true
      }, 500)
    }

    const handlePointerUp = (e: MouseEvent) => {
      clearTimeout(timer)
      if (!isLongPress) {
        setShow(prev => !prev)
      }
      isLongPress = false
    }

    el.addEventListener('pointerdown', handlePointerDown)
    el.addEventListener('pointerup', handlePointerUp)

    return () => {
      el.removeEventListener('pointerdown', handlePointerDown)
      el.removeEventListener('pointerup', handlePointerUp)
    }
  }, [])

  return (
    <div
      ref={ref}
      className="fixed bottom-[5px] left-[5px] z-[9999] rounded-[4px] bg-black px-[4px] py-[2px] text-white opacity-60"
    >
      <div
        className={cn(
          'my-[2px] h-[16px] w-[16px] cursor-pointer items-center justify-center rounded-full border border-white text-xs',
          show ? 'hidden' : 'flex',
        )}
      >
        i
      </div>
      <span className={cn(show ? 'block' : 'hidden')}>
        {apiEnv}
        {commitHash ? ` - #${commitHash}` : ''}
        {browser ? ` - ${browser}` : ''}
      </span>
    </div>
  )
}

export default Debugger
