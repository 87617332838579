import type { SendWebSocketData } from 'utils/hooks/useWebsocket'
import type { StateCreator } from 'zustand'

export interface TrackerState {
  sessionId: string
  ws: WebSocket | null
  sendMessage: SendWebSocketData
}

export interface TrackerAction {
  setSessionId: (sessionId: string) => void
  setWs: (ws: WebSocket | null) => void
  setSendMessage: (sendMessage: SendWebSocketData) => void
}

export const createTrackerSlice: StateCreator<
  TrackerState & TrackerAction
> = set => ({
  sessionId: '',
  ws: null,
  sendMessage: () => {},
  setSessionId: sessionId => set({ sessionId }),
  setWs: ws => set({ ws }),
  setSendMessage: sendMessage => set({ sendMessage }),
})
