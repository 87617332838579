import { create } from 'zustand'
import {
  createSessionSlice,
  type SessionAction,
  type SessionState,
} from './slice/sessionSlice'

import {
  createTrackerSlice,
  type TrackerAction,
  type TrackerState,
} from './slice/trackerSlice'

type StoreState = SessionState & TrackerState
type StoreActions = SessionAction & TrackerAction

export const useBoundStore = create<StoreState & StoreActions>()((...args) => ({
  ...createSessionSlice(...args),
  ...createTrackerSlice(...args),
}))
