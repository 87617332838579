import React from 'react'

export function useEventCallback<T extends (...args: any[]) => any>(fn: T): T {
  const ref: any = React.useRef(fn)

  // we copy a ref to the callback scoped to the current state/props on each render
  React.useLayoutEffect(() => {
    ref.current = fn
  })

  return React.useCallback(
    (...args: unknown[]) => ref.current.apply(void 0, args),
    [],
  ) as T
}
