import {
  SCREEN_PAGE_SUFFIX,
  SCREEN_POPUP_SUFFIX,
} from '../constant/trackerScreenConstant'

export function generateScreenName({
  key,
  screenType,
}: {
  key: string
  screenType: 'page' | 'popup'
}) {
  const screenName =
    screenType === 'page'
      ? `${key}${SCREEN_PAGE_SUFFIX}`
      : `${key}${SCREEN_POPUP_SUFFIX}`
  return screenName
}
