'use client'

import { useAutoBindTracker } from 'features/tracker/hooks/useAutoBindTracker'
import { useObserverTracker } from 'features/tracker/hooks/useObserverTracker'
import { usePageNameTracker } from 'features/tracker/hooks/usePageNameTracker'
import { useVisibilityTracker } from 'features/tracker/hooks/useVisibilityTracker'
import type { SessionTrackerBody } from '@paladise/tracker/types/tracker'

const TrackerRegister = ({
  sessionTrackerBody,
  sessionId,
}: {
  sessionTrackerBody: SessionTrackerBody
  sessionId: string
}) => {
  // initialize page visibility tracker
  useVisibilityTracker(sessionTrackerBody, sessionId)

  // initialize auto bind tracker
  useAutoBindTracker()

  // initialize observer tracker
  useObserverTracker()

  // initialize page name tracker
  usePageNameTracker()

  return null
}

export default TrackerRegister
