export type Env = 'local' | 'develop' | 'stage' | 'beta' | 'master'
export const ENV = {
  ENV: (process.env.NEXT_PUBLIC_ENVIRONMENT as Env) || 'local',
  PACKAGE_NAME: process.env.NEXT_PUBLIC_PACKAGE_NAME!,
  PACKAGE_VERSION: process.env.NEXT_PUBLIC_PACKAGE_VERSION!,
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY!,
  JWT_SECRET: process.env.JWT_SECRET!,
  COMMIT_HASH: process.env.COMMIT_HASH,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID!,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET!,
  FACEBOOK_CLIENT_ID: process.env.FACEBOOK_CLIENT_ID!,
  FACEBOOK_CLIENT_SECRET: process.env.FACEBOOK_CLIENT_SECRET!,
}

export const CONSUMER_BASE_URL = (() => {
  switch (ENV.ENV) {
    case 'local':
      return 'http://localhost:3000/'
    case 'develop':
    case 'stage':
      return 'https://paladise.xyz/'
    case 'beta':
      return 'https://paladise.bet/'
    case 'master':
      return 'https://paladise.ai/'
    default:
      return 'https://paladise.xyz/'
  }
})()

export const ENTERPRISE_BASE_URL = (() => {
  switch (ENV.ENV) {
    case 'local':
      return 'http://localhost:3000/'
    case 'develop':
    case 'stage':
      return 'https://paladise.cc/'
    case 'beta':
      return 'https://paladise.vip/'
    case 'master':
      return 'https://paladise.biz/'
    default:
      return 'https://paladise.cc/'
  }
})()

export const CDN_URL = 'https://storage.googleapis.com/ai-web-assets'

export const NO_PHOTO_PATH = CDN_URL + '/human/images/no_photo.svg' // if path is public can use /image...

export const BINDING_GOOGLE_ACCOUNT_URL = '/api/auth/bind/google'

export const AUTHORIZATION_GOOGLE_ACCOUNT_URL = '/api/auth/callback/google'

// --- Other config ---
export const CONSENT_COOKIE_NAME = 'pca'
export const USER_COOKIE_NAME = '__playsee_user'
export const USER_INFO_COOKIE_NAME = '__playsee_user_info'
export const AUTH_COOKIE_NAME = '__playsee_session'
export const EMBED_COOKIE_NAME = 'embed'

export const BIRTHDAY_FORMAT = 'MM/DD/YYYY'

export const DEFAULT_ERROR_MESSAGE = 'Response Error'

// TODO: translation
export const LINKS = [
  {
    href: '/home',
    title: 'Pals',
    tabIcon:
      'i-ps-user_s_big group-hover:i-ps-user_f_big group-data-[active="true"]:i-ps-user_f_big',
  },
  {
    href: '/discover',
    title: 'Discover',
    tabIcon:
      'i-ps-search_s_big group-hover:i-ps-search_f_big group-data-[active="true"]:i-ps-search_f_big',
  },
  {
    href: '/account',
    title: 'Account',
    tabIcon:
      'i-ps-user_rou_s_big group-hover:i-ps-user_rou_f_big group-data-[active="true"]:i-ps-user_rou_f_big',
  },
] as const

export const OS_THRESHOLDS: Record<string, number> = {
  iOS: 16,
  Android: 8,
}

export const BROWSER = {
  Chrome: 'Chrome',
  'Mobile Chrome': 'Mobile Chrome',
  Safari: 'Safari',
  'Mobile Safari': 'Mobile Safari',
  Firefox: 'Firefox',
  'Mobile Firefox': 'Mobile Firefox',
}

export const BROWSER_THRESHOLDS: Record<string, number> = {
  [BROWSER.Chrome]: 109,
  [BROWSER['Mobile Chrome']]: 109,
  [BROWSER.Safari]: 16.6,
  [BROWSER['Mobile Safari']]: 16.6,
}
