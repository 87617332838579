'use client'

import { HOST } from '@paladise/config/api-url'
import { useEffect, useRef } from 'react'

import { useBoundStore } from 'store/useBoundStore'
import { useWebsocket } from 'utils/hooks/useWebsocket'

export const TrackerInitializer = ({
  sessionId,
  v2Token,
}: {
  sessionId: string
  v2Token: string
}) => {
  const [ws, sendMessage] = useWebsocket(HOST.TRACKER, v2Token)
  const setWs = useBoundStore(state => state.setWs)
  const setSendMessage = useBoundStore(state => state.setSendMessage)
  const setSessionId = useBoundStore(state => state.setSessionId)
  const initialRef = useRef(false)

  useEffect(() => {
    if (!initialRef.current && ws) {
      setSessionId(sessionId)
      setWs(ws)
      setSendMessage(sendMessage)
      initialRef.current = true
    }
  }, [ws, sessionId, setSessionId, setWs, setSendMessage, sendMessage])

  return null
}
