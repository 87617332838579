'use client'

import { Link, usePathname } from 'lib/navigation'
import { useTranslations } from 'next-intl'
import React from 'react'
import { acceptConsent } from '../action/accept'

const ConsentBanner = ({ cookie }: { cookie: string | undefined }) => {
  const t = useTranslations()
  const [isToastVisible, setToastVisible] = React.useState(
    cookie ? cookie !== 'true' : true,
  )
  const pathname = usePathname()

  const handleCloseToast = () => {
    setToastVisible(false)
    acceptConsent()
  }

  if (!isToastVisible || pathname === '/embed') {
    return null
  }

  return (
    <div className="z-toast bg-floating-still text-label-still-l1 min900:bottom-24 min900:right-8 min900:max-w-[375px] min900:rounded-xl fixed bottom-0 right-0 flex w-full items-center px-[12px] py-[8px] text-[12px]">
      <p className="border-label-still-l3 border-r-[1px] pr-[12px]">
        {t.rich('cookies_policy_hint', {
          t1: () => (
            <Link href="/privacy" className="font-semibold" target="_blank">
              {t('common.cookie_policy')}
            </Link>
          ),
        })}
      </p>
      <button
        className="ml-[12px] shrink-0 px-[8px] font-semibold"
        onClick={handleCloseToast}
      >
        {t('ok')}
      </button>
    </div>
  )
}

export default ConsentBanner
