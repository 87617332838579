import type { TRACKER_EVENT_NAME } from '@paladise/tracker/types/tracker'
import { debounce } from '@paladise/utils/debounce'
import { usePathname } from 'lib/navigation'
import { useEffect, useMemo, useRef } from 'react'
import {
  TRACKER_EVENTS,
  TRACKER_MAPPING_DICTIONARY,
  type TrackerActionKeys,
  type TrackerEventKeys,
} from '../constant/trackerEventConstant'
import { type HandleEventTracker, useSendTracker } from './useSendTracker'

type TrackerHandler = {
  element: Element
  action: TRACKER_EVENT_NAME['action']
  eventHandler: (event: Event) => void
}

type TrackerHandlersMap = Map<TRACKER_EVENT_NAME['action'], TrackerHandler>

type EventHandler = Map<string, TrackerHandlersMap>

const tracker_attributes = ['data-tracker-click', 'data-tracker-typing']
const tracker_show = 'data-tracker-show'
const TRACKER_EVENTS_MAP = new Map(Object.entries(TRACKER_EVENTS))
const defaultDebounceTime = 1000

/**
 * Attaches trackers to elements.
 * @param handleEventTracker - The function to handle the event tracker.
 * @param debouncedHandleEventTracker - The debounced function to handle the event tracker.
 * @param eventHandlersRef - The reference to the map of event handlers.
 */
const attachTrackers = (
  handleEventTracker: (params: HandleEventTracker) => void,
  debouncedHandleEventTracker: (eventName: TrackerEventKeys) => void,
  eventHandlersRef: React.MutableRefObject<EventHandler>,
) => {
  const elements = document.querySelectorAll(
    `[${tracker_attributes.join('], [')}]`,
  )

  elements.forEach(element => {
    const elementId =
      element.id || `tracker-${Math.random().toString(36).substring(2)}`

    if (!element.id) {
      element.setAttribute('data-tracker-id', elementId)
    }

    let elementHandlers = eventHandlersRef.current.get(elementId)
    if (!elementHandlers) {
      elementHandlers = new Map()
      eventHandlersRef.current.set(elementId, elementHandlers)
    }

    tracker_attributes.forEach(attr => {
      const trackerKey = element.getAttribute(attr) as TrackerEventKeys
      if (!trackerKey) return

      const [, , action] = trackerKey.split('_') as [
        string,
        string,
        TRACKER_EVENT_NAME['action'],
      ]

      const trackerEvent = TRACKER_EVENTS_MAP.get(trackerKey)
      if (!trackerEvent) return

      if (!elementHandlers.has(action)) {
        const eventHandler = createEventHandler(
          trackerKey,
          handleEventTracker,
          debouncedHandleEventTracker,
        )
        attachEventListener({
          element,
          action,
          eventHandler,
          handlersMap: elementHandlers,
        })
      }
    })
  })
}

/**
 * Cleans up all attached trackers.
 * @param eventHandlersRef - The reference to the map of event handlers.
 */
const cleanupTrackers = (
  eventHandlersRef: React.MutableRefObject<EventHandler>,
) => {
  eventHandlersRef.current.forEach((elementHandlers, elementId) => {
    elementHandlers.forEach(handler => {
      removeEventListener({
        element: handler.element,
        action: handler.action,
        eventHandler: handler.eventHandler,
      })
    })
    eventHandlersRef.current.delete(elementId)
  })
}

/**
 * send tracker when data-tracker-show is found
 * @param handleEventTracker - The function to handle the event tracker.
 */
const sendShowTrackers = (
  handleEventTracker: (params: HandleEventTracker) => void,
) => {
  const showElements = document.querySelectorAll(`[${tracker_show}]`)
  showElements.forEach(element => {
    const showTrackerKey = element.getAttribute(
      tracker_show,
    ) as TrackerEventKeys
    if (showTrackerKey) {
      handleEventTracker({ eventName: showTrackerKey })
    }
  })
}

/**
 * Creates an event handler function for a specific tracker event.
 * @param eventName - The tracker event name.
 * @param handleEventTracker - The function to handle the event tracker.
 * @param debouncedHandleEventTracker - The debounced function to handle the event tracker.
 * @returns The event handler function.
 */
const createEventHandler = (
  eventName: TrackerEventKeys,
  handleEventTracker: (params: HandleEventTracker) => void,
  debouncedHandleEventTracker: (eventName: TrackerEventKeys) => void,
) => {
  return () => {
    const isTypingEvent = eventName.split('_')[2] === 'typing'

    if (isTypingEvent) {
      debouncedHandleEventTracker(eventName)
    } else {
      handleEventTracker({ eventName })
    }
  }
}

/**
 * Attaches an event listener to an element.
 * @param element - The element to attach the event listener to.
 * @param action - The tracker event action.
 * @param eventHandler - The event handler function.
 * @param handlersMap - The map of tracker event actions to tracker handlers.
 */
const attachEventListener = ({
  element,
  action,
  eventHandler,
  handlersMap,
}: {
  element: Element
  action: TRACKER_EVENT_NAME['action']
  eventHandler: (event: Event) => void
  handlersMap: TrackerHandlersMap
}) => {
  element.addEventListener(
    TRACKER_MAPPING_DICTIONARY[action.toUpperCase() as TrackerActionKeys],
    eventHandler,
  )
  handlersMap.set(action, { action, eventHandler, element })
}

/**
 * Removes an event listener from an element.
 * @param element - The element to remove the event listener from.
 * @param action - The tracker event action.
 * @param eventHandler - The event handler function.
 */
const removeEventListener = ({
  element,
  action,
  eventHandler,
}: {
  element: Element
  action: TRACKER_EVENT_NAME['action']
  eventHandler: (event: Event) => void
}) => {
  element.removeEventListener(
    TRACKER_MAPPING_DICTIONARY[
      action.toUpperCase() as TrackerActionKeys
    ] as keyof HTMLElementEventMap,
    eventHandler,
  )
}

/**
 * automatically binding tracker events to elements when firstTime initialized
 * @returns An object containing functions to attach and cleanup trackers.
 */
export const useAutoBindTracker = () => {
  const eventHandlersRef = useRef<EventHandler>(new Map())
  const { handleEventTracker } = useSendTracker()
  const pathName = usePathname()

  const debouncedHandleEventTracker = useMemo(
    () =>
      debounce((eventName: TrackerEventKeys) => {
        handleEventTracker({ eventName })
      }, defaultDebounceTime),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    sendShowTrackers(handleEventTracker)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    attachTrackers(
      handleEventTracker,
      debouncedHandleEventTracker,
      eventHandlersRef,
    )

    return () => {
      cleanupTrackers(eventHandlersRef)
    }
  }, [handleEventTracker, debouncedHandleEventTracker, pathName])
}
