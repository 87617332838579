import { usePathname } from 'lib/navigation'
import { useEffect, useRef } from 'react'
import { getScreenPageName } from '../utils/getScreenPageName'
import { useSendTracker } from './useSendTracker'

export const usePageNameTracker = () => {
  const currentPathName = usePathname()
  const { handleScreenTracker } = useSendTracker()
  const initialRef = useRef(false)

  useEffect(() => {
    if (initialRef.current) {
      const screenName = getScreenPageName({ currentPathName })
      handleScreenTracker({ screenName })
    }
    initialRef.current = true
  }, [currentPathName, handleScreenTracker])
}
