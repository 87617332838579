import { generateScreenName } from '../utils/generateScreenName'

export const SCREEN_PAGE_SUFFIX = '_page'
export const SCREEN_POPUP_SUFFIX = '_popup'

export const SCREEN_TYPES = {
  PAGE: 'page',
  POPUP: 'popup',
} as const

export const SCREEN_EVENT_NAME = 'screen_view' as const

export const ACCOUNT_SCREEN_NAME_MAPPING = {
  account: 'account',
  birthday: 'birthday',
  ['delete-account']: 'deleteaccount',
  email: 'email',
  ['link-account']: 'linkaccount',
  password: 'password',
  username: 'username',
  login: 'login',
  signup: 'signup',
  ['reset-password']: 'reset_password',
  plans: 'aibotplans',
} as const

export const BOT_SCREEN_NAME_MAPPING = {
  edit: 'editavatar',
  ['edit-background']: 'backgroundexperience',
} as const

export const SCREEN_NAME_MAPPING = {
  '': 'landing',
  error: 'error',
  ...ACCOUNT_SCREEN_NAME_MAPPING,
  ...BOT_SCREEN_NAME_MAPPING,
} as const

// handle some page tracker cannot access directly by router
export const EXCEPTIONAL_PAGE_SCREEN_VIEW_TRACKER = {
  faqs_page: 'faqs_page',
  faqsedit_page: 'faqsedit_page',
  chatroom_page: 'chatroom_page',
  selectpaymentmethod_page: 'selectpaymentmethod_page',
} as const

export const PAGE_SCREEN_VIEW_TRACKER = {
  ...Object.entries(SCREEN_NAME_MAPPING).reduce(
    (acc, [key, val]) => {
      acc[key as keyof typeof SCREEN_NAME_MAPPING] = generateScreenName({
        key: val,
        screenType: SCREEN_TYPES.PAGE,
      })
      return acc
    },
    {} as Record<keyof typeof SCREEN_NAME_MAPPING, string>,
  ),
  ...EXCEPTIONAL_PAGE_SCREEN_VIEW_TRACKER,
} as const

export const POPUP_SCREEN_VIEW_TRACKER = {
  deleteaccount_popup: 'deleteaccount_popup',
  logout_popup: 'logout_popup',
  birthdayerror_popup: 'birthdayerror_popup',
  resume_popup: 'resume_popup',
  changepayment_popup: 'changepayment_popup',
  changepayment1_popup: 'changepayment1_popup',
  cancelplan_popup: 'cancelplan_popup',
  faqsmore_popup: 'faqsmore_popup',
  removequestion_popup: 'removequestion_popup',
} as const
