import { PAGE_SCREEN_VIEW_TRACKER } from '../constant/trackerScreenConstant'

/**
 * Retrieves the screen name based on the provided path name.
 * @param currentPathName - current URL's pathname
 * @returns screen name
 */
export function getScreenPageName({
  currentPathName,
}: {
  currentPathName: string
}) {
  const paths = currentPathName.split('/').filter(path => path !== '')

  if (paths.length === 0) {
    return PAGE_SCREEN_VIEW_TRACKER['']
  }

  const screenViewKey = paths.at(-1) as keyof typeof PAGE_SCREEN_VIEW_TRACKER
  return PAGE_SCREEN_VIEW_TRACKER[screenViewKey] || ''
}
